import React, { useEffect, useState, useRef } from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import CountDown from "./countdown";
import { FaTelegramPlane, FaDiscord, FaTwitter } from "react-icons/fa";
import mainLogo from './songbird-flare.png';


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 150px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100px;
  @media (min-width: 767px) {
    width: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {


  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
      >
        <br />
        <br />
        <br />
        <a rel="noopener noreferrer" href="https://www.oracleswap.io">
          <StyledLogo alt={"logo"} src={"/config/images/logo.png"}/>
        </a>

        <div className="social-container">

          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Oracle_Swap">
            <FaTwitter color="gold" size={40} />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/WbDnWcRBxw">

            <FaDiscord color="gold" size={40} />
          </a>

          <a target="_blank" rel="noopener noreferrer" href="https://t.me/OracleSwapOffical">
            <FaTelegramPlane color="gold" size={40} />
          </a>

        </div>


        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            padding: 30,
            // backgroundColor: "var(--accent)",
            // borderRadius: 24,
            // border: "4px solid var(--secondary)",
            // boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
          }}
        >
          <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: 50,
              fontWeight: "bold",
              color: "var(--accent-text)",
            }}
          >
            COMING SOON!
          </s.TextTitle>

          <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: 35,
              fontWeight: "bold",
              color: "var(--accent-text)",
            }}
          >
            Ω Oracle Swap DEX Ω
          </s.TextTitle>

          <s.TextTitle
            style={{
              textAlign: "center",
              color: "white",
            }}
          >
            The Oracle Swap DEX is the premiere decentralized exchange on the Songbird & Flare Network!

          </s.TextTitle>

          <div className="container">
            <img src={mainLogo} width="300" alt="FlareSongbirdNetwork" />
          </div>

          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            <p></p>
            Ooops looks like this area isn't ready yet!!
            <p></p>
          </s.TextDescription>


          <br />

          <StyledButton
            onClick={(e) => {
              window.open("https://www.oracleswap.io")
            }}
          >
            GO BACK TO ORACLESWAP.IO
          </StyledButton>

        </s.Container>

        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            padding: 30,
            // backgroundColor: "var(--accent)",
            // borderRadius: 24,
            // border: "4px solid var(--secondary)",
            // boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
          }}
        >
          {/* <CountDown />
          <br />
          <StyledLink target={"_blank"} href={"https://discord.gg/jbncxhT393"}>
            {"CLICK HERE TO SEE THE PLEDGE WEBSITE ORACLESWAP.XYZ"}
          </StyledLink>

          <s.SpacerLarge /> */}

        </s.Container>



        <s.SpacerLarge />

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>

        </s.Container>

        <s.SpacerLarge />



      </s.Container>
    </s.Screen >
  );
}

export default App;
